/* eslint-disable import/prefer-default-export */
import Axios from "axios";
import config from "configs/config";

const returnError = (err) => {
  console.log(err);
  const data = {
    success: false,
    message: "There is a problem",
  };
  return data;
};
// exports.getUserFromToken = async (token) => {
//   const decode = await jwt.verify(token, config.secret);
//   return decode.user_id;
// };
export const getIpInfo = async () => {
  try {
    const url = `https://ip.nf/me.json`;
    const result = await Axios.get(url);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const getIconsList = async (token) => {
  try {
    const url = `${config.apiserver}/general/geticons/`;
    const result = await Axios.get(url, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const startScrape = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startScrape`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const startScrapeWoolworthsPages = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startscrapewoolworthspages`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const startScrapeWoolUnits= async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startscrapeunits`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
 

export const startScrapePageDivision = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startScrapePageDivision`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const startScrapeColes = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startscrapecoles`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
export const startScrapeColesPageCount = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startScrapeColesPageCount`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const startScrapeAldi = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/startscrapealdi`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    console.log(result.data);
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};

export const scrapeWoolworthBrands = async (newDatas, token) => {
  try {
    const url = `${config.apiserver}/dashboard/scrape/scrapeWoolworthBrands`;
    const result = await Axios.post(url, newDatas, {
      headers: {
        "x-access-token": token,
      },
    });
    return result.data;
  } catch (err) {
    return returnError(err);
  }
};
