import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Acromegaly React components
import MDBox from "components/MDBox";
import LoadingOverlay from "react-loading-overlay";
// Acromegaly React example components
// Acromegaly React contexts
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "utiliz/showMessage";
import { catchMessage } from "utiliz/showMessage";
import { getColesNewProductsForConfirm } from "controllers/ProductWithoutBrandController";
import { storeColesProductVariant } from "controllers/ProductWithoutBrandController";
import { successMessage } from "utiliz/showMessage";
import { confirmMessage } from "utiliz/showMessage";
import { Button, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";

let newData = {
  product: "",
  amount: 0,
  store: "",
  discount: "",
  unitPrice: "",
  price: "",
  description: "",
  title: "",
  name: "",
  currency: "AUD",
};

function ViewColesProduct(props) {
  const { id, closeModal, getData, handleSetCoId, rowsPerPage, page, search } =
    props;
  const [controller] = useMaterialUIController();
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [existDatas, setExistDatas] = useState([]);
  const [images, setimages] = useState([]);

  const [selecedItems, setSelecedItems] = useState([]);
  const [fields, setFields] = useState(newData);
  const [, setReload] = useState(new Date());
  const [amountEdit, setAmountEdit] = useState(false);

  const navigate = useNavigate();
  const clearForm = () => {
    newData = {
      id: "",
      amount: 0,
      product: "",
      store: "",
      discount: "",
      unitPrice: "",
      price: "",
      description: "",
      title: "",
      name: "",
      currency: "AUD",
    };
    setFields(newData);
    setReload(new Date());
  };

  const getDatas = async (id) => {
    try {
      console.log("ssss", id);
      setLoading(true);
      const res = await getColesNewProductsForConfirm(
        { id: id },
        controller.token
      );
      setLoading(false);
      if (res.success) {
        console.log(res.data);
        setDatas(res.data);
        console.log(res.data);
        setExistDatas(res.data.sameWords);
        setimages(res.images);
        let temp = fields;
        temp.amount = res.data.amount;
        temp.unitPrice = res.data.unitPrice;
        temp.price = res.data.price;
        temp.discount = res.data.discount;
        temp.description = res.data.title;
        temp.title = res.data.title;
        temp.name = res.data.name;
        setFields(temp);
        console.log("resspr", res.images);

        setReload(new Date());
      } else {
        errorMessage(res.message);
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleSubmitClick = async (productId, image) => {
    try {
      let temp = fields;
      temp.id = id;
      temp.product = productId;
      temp.image = image;
      console.log("temptemp", temp);
      // setFields(temp);
      // setReload(new Date());
      if (await confirmMessage("Do you want to store?")) {
        setLoading(true);
        const res = await storeColesProductVariant(temp, controller.token);
        setLoading(false);
        if (res.success) {
          successMessage(res.message);
          getDatas(handleSetCoId());
          getData(rowsPerPage, page + 1, search);
          clearForm();
        } else {
          errorMessage(res.message, res.code);
        }
        if (res.code === 401) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      setLoading(false);
      catchMessage(error);
    }
  };

  const handleDeleteClick = async (productId) => {
    try {
      setExistDatas(existDatas.filter((a) => a._id != productId));
      setReload(new Date());
    } catch (error) {
      catchMessage(error);
    }
  };

  useEffect(() => {
    getDatas(id);
  }, []);

  return (
    <LoadingOverlay
      className="overlayclass"
      active={loading}
      spinner
      text="Please wait ..."
    >
      {datas && (
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} xs={12}>
            {/* coles */}
            <fieldset style={{ backgroundColor: "lightGray" }}>
              <MDBox py={2} px={3}>
                <div
                  style={{ paddingLeft: 20, paddingRight: 20, color: "black" }}
                >
                  <IconButton
                    style={{ margin: "2px" }}
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      setSelecedItems([]);
                      setExistDatas(datas.sameWords);
                    }}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  {datas &&
                    datas.title &&
                    datas.title.split(" ").map((item) => (
                      <b
                        style={{
                          cursor: "pointer",
                          color: selecedItems.includes(item) ? "red" : "#000",
                        }}
                        onClick={() => {
                          let selectedtemp = selecedItems;
                          if (
                            selecedItems.filter((a) => a == item).length > 0
                          ) {
                            selectedtemp = selecedItems.filter(
                              (a) => a !== item
                            );
                            setSelecedItems(selectedtemp);
                          } else {
                            selectedtemp.push(item);
                            setSelecedItems(selectedtemp);
                          }
                          let temp = datas.sameWords;
                          //if (existDatas.length == 0) temp = datas.sameWords;
                          if (selectedtemp.length == 0) {
                            setExistDatas(datas.sameWords);
                            return;
                          }
                          let withArray = [];
                          if (selectedtemp.length > 0) {
                            temp.map((itemm) => {
                              if (
                                itemm.words.filter(
                                  (a) =>
                                    a == selectedtemp[0].toLowerCase() ||
                                    a + "s" == selectedtemp[0].toLowerCase()
                                ).length !== 0
                              ) {
                                withArray.push(itemm);
                              }
                            });
                          }
                          for (let i = 1; i < selectedtemp.length; i++) {
                            temp = withArray;
                            withArray = [];
                            temp.map((itemm) => {
                              if (
                                itemm.words.filter(
                                  (a) =>
                                    a == selectedtemp[i].toLowerCase() ||
                                    a + "s" == selectedtemp[i].toLowerCase()
                                ).length !== 0
                              ) {
                                withArray.push(itemm);
                              }
                            });
                          }
                          console.log(selectedtemp.length, withArray.length);
                          setExistDatas(withArray);
                        }}
                      >
                        {item + " "}
                      </b>
                    ))}
                </div>
                <Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    <img
                      alt={` `}
                      src={`${datas.image}`}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} md={6} xs={6}>
                      <Grid container>
                        <b> Unit : </b>
                        {datas.unit}
                      </Grid>
                      <Grid container>
                        <b> Amount : </b>
                        {datas.amount}
                      </Grid>
                      <Grid container>
                        <b> Price : </b>
                        {datas.price}
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}>
                      <Button
                        variant="contained"
                        style={{
                          height: 30,
                          color: "white",
                          backgroundColor: "rgb(0 78 185)",
                        }}
                        onClick={() => {
                          navigate(`/storeproduct/${datas._id}`);
                        }}
                      >
                        Store As New Product
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </fieldset>
            <Grid container mt={2}>
              <Button
                variant="contained"
                color="info"
                style={{
                  color: "blue",
                  backgroundColor: "white",
                  border: "1px solid blue",
                  width: "100%",
                }}
                onClick={() => {
                  setSelecedItems([]);
                  getDatas(handleSetCoId(1));
                  // getData(rowsPerPage, page + 1, search);
                }}
              >
                Next Product
              </Button>
            </Grid>
          </Grid>
          {/* wolworthProducts */}
          <Grid
            item
            lg={7}
            md={7}
            xs={12}
            style={{ maxHeight: 800, overflowY: "scroll" }}
          >
            {existDatas?.map((item, index) => (
              <fieldset style={{ marginBottom: 3 }}>
                {/* <legend
                  style={{ paddingLeft: 20, paddingRight: 20, color: "black" }}
                >
                  <b>{item.product.title}</b>
                </legend> */}
                <MDBox py={2} px={3}>
                  <Grid container>
                    <Grid paddingLeft={0} item lg={3} md={3} sm={12}>
                      <img
                        alt={` `}
                        src={`${
                          images.filter(
                            (a) => a.product + "" == item.product._id + ""
                          )[0]?.image
                        }`}
                        style={{
                          width: 180,
                          height: 180,
                        }}
                      />
                    </Grid>

                    <Grid item lg={9} md={9} sm={12}>
                      <Grid container>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                          <Grid container>
                            <Grid item lg={9} md={9} sm={12}>
                              <b> Title : </b>
                              <b>{item.product.title}</b>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item lg={9} md={9} sm={12}>
                              <b> Same words : </b>
                              {item.words?.map(
                                (itemx, index) =>
                                  itemx +
                                  (index < item.words.length - 1 ? "  ,  " : "")
                              )}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item lg={3} md={3} sm={12}>
                              <b> Unit : </b>
                              {item.product.unit}
                            </Grid>
                            <Grid item lg={5} md={5} sm={12}>
                              <b
                                onClick={() => {
                                  setAmountEdit(!amountEdit);
                                }}
                              >
                                {" "}
                                Amount :{" "}
                              </b>
                              <EditIcon
                                onClick={() => {
                                  setAmountEdit(!amountEdit);
                                }}
                              />
                              {!amountEdit ? (
                                item.product.amount
                              ) : (
                                <TextField
                                  style={{ width: 70 }}
                                  type={"number"}
                                  value={item.product.amount}
                                  onChange={(event) => {
                                    const temp = fields;
                                    temp.amount = event.target.value;
                                    item.product.amount = event.target.value;
                                    setFields(temp);
                                    setReload(new Date());
                                  }}
                                ></TextField>
                              )}
                            </Grid>
                            <Grid item lg={4} md={4} sm={12}>
                              <b> Price : </b>
                              {item.product.price}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={3}>
                          <Grid container marginBottom={2} marginTop={2}>
                            <Button
                              variant="contained"
                              style={{
                                height: 90,

                                color: "white",
                                backgroundColor: "#1b5e20",
                              }}
                              onClick={() => {
                                handleSubmitClick(
                                  item.product._id,
                                  images.filter(
                                    (a) =>
                                      a.product + "" == item.product._id + ""
                                  )[0]?.image
                                );
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid container>
                            <Button
                              variant="contained"
                              color="error"
                              style={{
                                color: "red",
                                backgroundColor: "white",
                                border: "1px solid red",
                              }}
                              onClick={() => {
                                handleDeleteClick(item._id);
                              }}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </fieldset>
            ))}
          </Grid>
        </Grid>
      )}
    </LoadingOverlay>
  );
}

export default ViewColesProduct;
